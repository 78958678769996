$primary-color: #00349f;
$toolbar-color: #fff;
$toolbar-height: 64px;
$warning-color: #d22630;
$label-color: rgba(0, 0, 0, 0.54);
$border-color: #9a9a9a;
$icon-color: rgba(0, 0, 0, 0.87);
$success-color: #28a745;
$no-data-color: #222222;
$no-data-background: #efefef;
$no-data-border-color: #dedede;
$odd-color: #e6e6e6;
$even-color: #f8f8f8;
$app-accent: #00349f;
$accent-color: #00349f;
$disabled-color: #d6d6d6;

// grey color
$grey-800: #203665;
$grey-700: #707070;
$grey-600: #8f9bb3;
$grey-300: #d7d7d7;
$grey-200: #d3d8de;
$grey-100: #eaeef4;

// green
$green-600: #1dbaa9;
