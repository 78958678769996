/* You can add global styles to this file, and also import other style files */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './styles/_variables';
@import './styles/_base-styles';

// @font-face {
//     font-family: 'Gill Light';
//     src: url('assets/fonts/Gill\ Sans\ Nova\ Light.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: Arial, Serif, Sans-serif, cursive, fantasy, Monospace;
//     src: url('assets/fonts/Gill\ Sans\ Nova\ Medium.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    width: 100%;
    height: 100%;
}

body {
    display: block;
    font-family: Arial, Serif, Sans-serif, cursive, fantasy, Monospace;
}

#app-container {
    margin: 0 auto;
}

.mat-raised-button,
.mat-button {
    border-radius: 4px !important;
    // text-transform: uppercase !important;

    &.mat-primary {
        // background-color: $primary-color;
        background-color: #203665;
    }

    &.mat-accent {
        background-color: $green-600;
        color: #fff;
    }
}

.mat-toolbar.mat-primary {
    // background-color: $primary-color;
    background-color: #203665;
}

.mat-header-row {
    min-height: 40px;
}

.mat-header-cell {
    // background-color: $primary-color;
    background-color: #203665;
    color: #ffffff;
    // text-transform: uppercase;
    border-right: 2px white solid;
    padding: 0 8px;

    &:first-child {
        border-top-left-radius: 8px;
    }

    &:last-child {
        border-top-right-radius: 8px;
    }
}

.mat-row {
    border: 0;
    min-height: 48px;
    border-radius: 4px;

    &:nth-child(odd) {
        background-color: #EAEEF4;
    }

    &:nth-child(even) {
        // background-color: $even-color;
        background-color: white;
    }

    &:last-child {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    align-items: stretch;
}

.mat-cell {
    // border-right: 2px #ffffff solid;
    padding: 0 8px;
}

.mat-drawer-inner-container {
    padding-left: 1.5em;
    padding-right: 1.5em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    // background-color: $primary-color;
    background-color: #203665;
    color: #ffffff;
    max-width: 300px;
}

.questionnaire-sidenav .mat-drawer-container {
    background-color: transparent;
}

.questionnaire-sidenav .mat-drawer-inner-container,
.questionnaire-sidenav .mat-tree {
    background-color: transparent;
    color: $primary-color;
    padding-left: 0;

    .form-field-label {
        justify-content: start !important;
    }

    .mat-button {
        border: none;
    }

    .mat-icon-button,
    .mat-icon {
        color: $primary-color;
    }
}

.mat-list-item-content {
    // background-color: $primary-color;
    background-color: #203665;
    color: #ffffff;
    justify-content: center;
    height: 40px;
}

.nav-item.mat-list-item.active {
    .mat-list-item-content {
        color: #ffffff !important;
        border-radius: 25px;
        margin: 10px;
    }
}

.form-wrapper {
    display: grid;
    grid-template-columns: 3fr 3fr 3fr;
}

.dashboard-content .form-wrapper {
    display: grid;
    grid-template-columns: 3fr 3fr 6fr !important;
}

.dashboard-content .form-field-label {
    justify-content: start;
    margin-bottom: 0 !important;
    padding: 0 0 8px 0 !important;
}

.form-field-label {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: $grey-800;
    margin-bottom: 20px;
    min-width: 200px;
    font-family: Arial, Serif, Sans-serif, cursive, fantasy, Monospace !important;
}

.form-field-label-2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-transform: uppercase;
    color: $primary-color;
    margin: 0 5px;
}

.form-field-label-3 {
    display: flex;
    justify-content: left;
    align-items: center;
    text-transform: uppercase;
    color: $primary-color;
    margin-bottom: 8px;
    min-width: 200px;
}

.mat-form-field-appearance-outline {
    .mat-form-field-outline-thick {
        color: $primary-color !important;
    }

    &.mat-form-field-invalid {
        .mat-form-field-outline-thick {
            color: $warning-color !important;
        }
    }

    .mat-form-field-outline-start {
        border-style: none !important;
        border-radius: 5px !important;
    }

    .mat-form-field-outline-end {
        border-left-style: double !important;
        border-radius: 5px !important;
    }

    .mat-form-field-infix {
        padding-left: 0.8em !important;
        // padding-top: 1.2em !important;
        border-top-width: 0 !important;
    }

    .mat-form-field-suffix {
        top: 0.65em !important;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $primary-color;
    font-family: Arial, Serif, Sans-serif, cursive, fantasy, Monospace;
    font-weight: 500;
}

h3 {
    color: $primary-color;
    font-family: Arial, Serif, Sans-serif, cursive, fantasy, Monospace;
}

h1 {
    font-size: 3.5rem;
}

h2 {
    font-size: 2.5rem;
}

h3 {
    font-size: 1.5rem;
}

h4 {
    font-size: 1rem;
}

h5 {
    font-size: 1rem;
}

h6 {
    font-size: 1rem;
}

.mat-input-element {
    caret-color: $primary-color !important;
}

.mat-card-title {
    color: $primary-color;
}

a:link,
a:visited {
    color: $primary-color;
}

a:hover {
    text-decoration: none;
}

.mat-card,
.mat-button,
.mat-fab,
.mat-flat-button,
.mat-icon-button,
.mat-mini-fab,
.mat-raised-button,
.mat-stroked-button {
    font-family: Arial, Serif, Sans-serif, cursive, fantasy, Monospace !important;
}

.mat-card {
    padding-left: 80px !important;
    padding-right: 80px !important;
    padding-top: 40px !important;
}

.mat-button-focus-overlay {
    display: none;
}

.mat-card-title {
    font-family: Arial, Serif, Sans-serif, cursive, fantasy, Monospace;
    font-weight: 500;
}

.mat-step-header .mat-step-icon.mat-step-icon-state-number {
    border: 2px solid $primary-color;
    background-color: white !important;

    &.mat-step-icon-selected {
        // background-color: $primary-color !important;
        background-color: #203665 !important;
    }
}

.header {
    background-color: #eef4ff;
}

.agency {
    font-family: Arial, Serif, Sans-serif, cursive, fantasy, Monospace, Helvetica !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 48px !important;
    color: #0035a0;
}

.mat-button.mat-underline span.mat-button-wrapper {
    padding-bottom: 1px;
    border-bottom: 1px solid $primary-color;
}

.mat-checkbox-checked .mat-checkbox-background {
    // background-color: $primary-color !important;
    background-color: #1DBAA9 !important;
}

.mat-tab-group.ios-style-tabs {
    .mat-tab-label {
        height: 36px;
        flex: 1 0 auto;
        color: $primary-color;
        font-family: Arial, Serif, Sans-serif, cursive, fantasy, Monospace, Helvetica;
        font-weight: 500;
        opacity: 1 !important;

        border: 2px solid $primary-color;

        &:active {
            background-color: transparent;
        }

        &:first-child {
            border-top-left-radius: 64px;
            border-bottom-left-radius: 64px;
        }

        &:last-child {
            border-top-right-radius: 64px;
            border-bottom-right-radius: 64px;
        }

        &.mat-tab-label-active {
            background: $primary-color;
            color: white;
        }
    }

    .mat-ink-bar {
        display: none;
    }
}

questionnaire-assessment-preview {
    height: 100%;
}

questionnaire-assessment-preview,
questionnaire-response-dynamic-form-container,
questionnaire-response-file-upload-container {
    .mat-step-header .mat-step-label {
        color: $primary-color !important;
        font-size: 1.5rem;
        font-family: Arial, Serif, Sans-serif, cursive, fantasy, Monospace, Helvetica;
        font-weight: bold;
    }

    .mat-step-icon.mat-step-icon-state-edit {
        border: 2px solid $primary-color;
        background-color: transparent;
    }

    .mat-step-icon-content {
        display: none;
    }
}

.mat-tooltip {
    font-size: 1rem;
}

.mat-option .mat-option-text {
    color: $grey-800;
    font-family: Arial, Serif, Sans-serif, cursive, fantasy, Monospace;
    font-size: 14px;
}

.mat-button.mat-warn,
.mat-icon-button.mat-warn,
.mat-stroked-button.mat-warn {
    color: $warning-color;
}

.section-label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-transform: uppercase;
    color: $primary-color;
    font-weight: bold;
    font-size: 1.25rem;
    margin: 32px 0;
    min-width: 200px;
}

.mat-table,
.mat-select {
    font-family: Arial, Serif, Sans-serif, cursive, fantasy, Monospace Medium !important;
}

::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #8888885c;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.layout-padding-l {
    padding-left: 190px !important;
}

.layout-padding-r {
    padding-right: 190px !important;
}

// .layout-padding {
// padding-left: 3rem !important;
// padding-right: 3rem !important;
// }

@media only screen and (max-width: 460px) {
    .layout-padding-l {
        padding-left: 0px !important;
    }
    .layout-padding-r {
        padding-right: 0px !important;
    }
}

.page-section-title {
    text-align: center;
    font-family: "Roboto", Serif, Sans-serif, Cursive, fantasy, Monospace;
    font-style: normal;
    font-weight: 700;
    font-size: 28px !important;
    color: #203665;
    line-height: 40px;
}

.bottom-line {
    border: none;
    border-bottom: 20px solid #e0e0e0;
    margin: 30px 0 0 0;
}

.language-switcher-included {
    position: relative;
}

.break-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.document-list-item {
    color:#203665 !important;
}

.highlight {
    color: #203665;
    font-weight: 700;
}
