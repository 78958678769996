@import './_variables';

div.title {
    background: $primary-color;
    color: $toolbar-color;
    height: $toolbar-height;
    line-height: $toolbar-height;
}

.wrap-text {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
}

mat-cell {
    padding: 0.5rem;
}

.container {
    height: 100%;

    display: flex;
    flex-direction: column;
}

.column {
    display: flex;

    > label {
        color: $label-color;
    }
}

mat-icon {
    &.dark-gray {
        color: $icon-color;
    }

    &.warn {
        color: $warning-color;
    }

    &.success {
        color: $success-color;
    }
}

div.no-data {
    padding: 1rem 0;
    font-size: 1.5rem;

    border: 1px solid $no-data-border-color;
    margin: 5px 0;
    background: $no-data-background;
    color: $no-data-color;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    $toolbar-height: 56px;

    div.title {
        height: $toolbar-height;
        line-height: $toolbar-height;
    }
}
